<template>
  <div>
    <v-alert
      v-for="(item, index) in dataWorkedDays"
      v-bind:key="item.id"
      prominent
      type="error"
      dense
      color="EoleError"
      text
      outlined
    >
      <v-row align="center">
        <v-col class="">
          Veuillez valider vos temps de travail pour le mois de {{ item.displayMonth }} <br />
          <span v-if="item.state === 2"> <strong>Note du manager</strong> {{ item.manager_comment }}</span>
        </v-col>
        <v-col class="text-right">
          <v-row>
            <v-col cols="12" md="12">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="
                      () => {
                        generateDaysOfLastMonth(item.month)
                        dialogVisible[index] = true
                        editedMonth = item.month
                      }
                    "
                    icon
                    color="EoleError"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Voir les jours travaillés</span>
              </v-tooltip>

              <v-dialog v-model="dialogVisible[index]" max-width="1500px">
                <v-card>
                  <v-dialog v-model="dialogModify" max-width="500px">
                    <v-card>
                      <v-card-text>
                        <v-select
                          v-model="modifyLeave.type"
                          outlined
                          dense
                          :items="items"
                          item-text="label"
                          item-value="type"
                          label="Type de congé"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>({{ item.type }}) {{ item.label }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmModify(modifyLeave.day)" color="EoleGreen" class="white--text"
                          >confirmer !</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogModifyPost" max-width="500px">
                    <v-card>
                      <v-card-text>
                        <v-select
                          v-model="modifyLeave.type"
                          outlined
                          dense
                          :items="items"
                          item-text="type"
                          item-value="type"
                          label="Type de congé"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>({{ item.type }}) {{ item.label }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </v-card-text>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="12" class="mt-n10">
                            <v-switch inset label="Demi-journée" v-model="suggestedLeave.is_half_day"></v-switch>
                          </v-col>
                          <v-col cols="12" md="12" v-if="suggestedLeave.is_half_day" class="mt-n10">
                            <v-radio-group v-model="suggestedLeave.half_day_type">
                              <v-radio
                                v-for="n in [
                                  { id: 1, label: 'Matin' },
                                  { id: 2, label: 'Après-midi' },
                                ]"
                                :key="n.id"
                                :label="`${n.label}`"
                                :value="n.id"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmModifyPost(modifyLeave.day)" color="EoleGreen" class="white--text"
                          >Confirmer !</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- Contenu de votre dialogue -->
                  <v-card-title> Vos jours travaillés pour le mois de {{ item.displayMonth }}</v-card-title>
                  <!-- {{ leaves }} {{ item.month }} -->
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="2">
                        <v-badge dot color="EoleBlue" inline></v-badge> <span>Congés approuvés</span>
                      </v-col>
                      <v-col cols="12" md="10" class="text-right">
                        <span
                          >Nombre de jours travaillés (JT) : {{ workedDays }} Nombre de jours non travaillés :
                          {{ absentDays }}</span
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-simple-table>
                          <thead>
                            <tr>
                              <th v-for="(day, index) in daysOfLastMonth" :key="index">{{ day.format("DD MMM") }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <!-- <td>{{ collaborator.lastname }} {{ collaborator.firstname }}</td> -->
                              <td
                                v-for="(day, index) in daysOfLastMonth"
                                :key="index"
                                :class="[getLeaveClass(day), 'no-padding']"
                              >
                                <v-row>
                                  <v-col
                                    v-if="getLeaveClass(day) != 'working'"
                                    :class="['white--text justify-center text-center', getLeaveClass ? 'mb-5' : 'mt-5']"
                                    cols="12"
                                    md="12"
                                    @click="suggestModify(day.format('YYYY-MM-DD'))"
                                    style="cursor: pointer; text-align: center; padding: 10px; border-radius: 5px"
                                  >
                                    <div
                                      v-if="getLeaveClass(day)"
                                      :class="['white--text']"
                                      :style="getHalf(day) ? 'position: relative; top: 40px' : null"
                                    >
                                      {{ getHolidayInformation(day) }}
                                    </div>
                                  </v-col>
                                  <v-col
                                    v-else
                                    :class="['white--text justify-center text-center', getLeaveClass ? 'mb-5' : 'mt-5']"
                                    cols="12"
                                    md="12"
                                    @click="suggestModifyPost(day.format('YYYY-MM-DD'))"
                                    style="cursor: pointer; text-align: center; padding: 10px; border-radius: 5px"
                                  >
                                    <div class="EoleError--text">JT</div>
                                  </v-col>
                                  <v-col
                                    @click="suggestModifyPost(day.format('YYYY-MM-DD'))"
                                    v-if="getHalf(day)"
                                    cols="12"
                                    md="12"
                                    :class="getHalfDayClass(day)"
                                    >JT</v-col
                                  >

                                  <!-- <v-col cols="12" md="5" v-else> {{ days }} </v-col> -->

                                  <!-- <div v-if="getLeaveClass(collaborator, day)" class="white--text">{{ days }} jours</div> -->
                                </v-row>
                                <!-- <div v-if="getLeaveClass(collaborator, day)" class="white--text">
                    {{ days }} jours {{ getHolidayType(collaborator, day) }}
                  </div> -->
                                <!-- <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      @click="suggestModify(day.format('DD MMM'))"
                                      elevation="0"
                                      fab
                                      tile
                                      small
                                      block
                                      style="with: 100%"
                                      v-on="on"
                                      v-bind="attrs"
                                      color="transparent"
                                    >
                                      <div v-if="getLeaveClass(day)" class="white--text">ABS</div>
                                      <div v-if="!getLeaveClass(day)">JT {{ getLeaveClass(day) }}</div>
                                    </v-btn>
                                  </template>
                                  <span>Suggérer une modification pour le {{ day.format("DD MMM") }}</span>
                                </v-tooltip> -->
                              </td>
                            </tr>
                          </tbody>
                          <!-- ... -->
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text class="text-center">
                    Cliquez sur vos jours de travail pour effectuer une modification.
                  </v-card-text>

                  <!-- <v-card-text> Contenu du dialogue </v-card-text> -->
                  <v-card-actions>
                    <v-btn elevation="0" @click="dialogVisible = false">Fermer</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="postDaysWork" elevation="0" color="EoleGreen" class="white--text"
                      >Confirmer mes jours travaillés pour {{ item.displayMonth }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-alert>

    <v-row class="mt-5">
      <v-col class="d-flex" cols="12" md="4" sm="6">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
              <v-card-subtitle>
                <v-avatar color="EoleBlueLighten" class="mr-2">
                  <i class="fa-solid fa-circle-info fa-1x EoleBlue--text" style="font-size: 1.5rem"></i> </v-avatar
                >Mon contrat de travail</v-card-subtitle
              >
              <div class="no-padding">
                <v-card-text>
                  <v-row
                    ><v-col cols="12" md="12" class="text-left">
                      Mon manager :
                      <strong>{{ service.lastname_manager }} {{ service.firstname_manager }}</strong>
                    </v-col>
                    <v-col cols="12" md="12" class="text-left mt-n5">
                      Contrat de travail :
                      <strong>{{ contract.designation }}</strong>
                    </v-col>
                    <v-col cols="12" md="12" class="text-left mt-n5">
                      Congés payés du {{ msgYear }}
                      :
                      <strong>{{ remain.remain_paid_leave_last_year }}</strong>
                    </v-col>
                    <v-col cols="12" md="12" class="text-left mt-n5">
                      Congés payés du {{ msgNextYear }}:
                      <strong>{{ remain.remain_paid_leave }}</strong>
                    </v-col>
                    <v-col cols="12" md="12" class="text-left mt-n5" v-if="contract.id == 2">
                      Jours forfait compensatoire ({{ year }}) :
                      <strong>{{ remain.compensatory_package_leave }}</strong>
                    </v-col>
                    <v-col cols="12" md="12" class="text-left mt-n5" v-else-if="contract.id == 4">
                      Récupération du temps de travail ({{ year }}) :
                      <strong>{{ remain.recovery_working_time_leave }}</strong>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
              <!-- <v-card-text v-else class="text-center">
                  <p style="font-size: 1.5rem">🎉</p>
                  <p>Vous n'avez aucun rendez-vous pour le moment.</p>
                </v-card-text> -->
            </v-card>
          </template>
          <span>Le solde de jours restant au 1er du mois courrant ({{ getFirstDayOfCurrentMonth }}). </span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="3" sm="6">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
              <v-card-subtitle>
                <v-avatar color="EoleBlueLighten" class="mr-2">
                  <i class="fa-regular fa-calendar-plus fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                </v-avatar>
                Mon solde de congés payés.
              </v-card-subtitle>
              <div class="d-flex flex-column flex-grow-1 mt-10">
                <v-card-text class="text-center flex-grow-1">
                  <h2 class="font-weight-bold" style="font-size: 2rem">{{ remain.remain_paid_leave_last_year }}</h2>
                  <small>Demande en cours : {{ getRemainPaidDemand() }} jours</small>
                </v-card-text>
              </div>
              <v-card-actions class="text-center mx-10">
                <div class="text-center mt-5">
                  <span style="font-size: 0.8rem">A prendre avant le </span>
                  <strong class="EoleYellow--text"> 31 mai {{ year }}</strong>
                </div>
              </v-card-actions>
            </v-card>
          </template>
          <span>Le solde de jours restant au 1er du mois courant ({{ getFirstDayOfCurrentMonth }}). </span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="3" sm="6" v-if="contract.id == 2">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
              <v-card-subtitle>
                <v-avatar color="EoleBlueLighten" class="mr-2">
                  <i class="fa-solid fa-plus fa-1x EoleBlue--text" style="font-size: 1.5rem"></i> </v-avatar
                >Mon solde de JFC</v-card-subtitle
              >
              <div class="d-flex flex-column flex-grow-1 mt-10">
                <v-card-text class="text-center flex-grow-1">
                  <h2 class="font-weight-bold" style="font-size: 2rem">{{ remain.compensatory_package_leave }}</h2>
                  <small>Demande en cours : {{ getRecoveryDemand() }} jours</small>
                </v-card-text>
              </div>
            </v-card>
          </template>
          <span>Les appels en attente sont affichés dans la liste ci-dessous. </span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="3" sm="6" v-else-if="contract.id == 4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
              <v-card-subtitle>
                <v-avatar color="EoleBlueLighten" class="mr-2">
                  <i class="fa-solid fa-plus fa-1x EoleBlue--text" style="font-size: 1.5rem"></i> </v-avatar
                >Mon solde de RTT</v-card-subtitle
              >
              <div class="no-padding mt-10">
                <v-card-text class="text-center">
                  <h2 class="font-weight-bold" style="font-size: 2rem">{{ remain.recovery_working_time_leave }}</h2>
                  <small>Demande en cours : {{ getRecoveryDemand() }} jours</small>
                </v-card-text>
              </div>

              <v-card-actions class="text-center mx-8">
                <div class="text-center mt-5">
                  <span style="font-size: 0.8rem">A prendre avant le </span>

                  <strong class="EoleYellow--text"> 31 Décembre {{ year }}</strong>
                </div>
              </v-card-actions>
            </v-card>
          </template>
          <span>Les appels en attente sont affichés dans la liste ci-dessous. </span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="2" sm="6">
        <v-row>
          <v-col cols="12" md="12">
            <v-dialog
              v-model="dialogPostHoliday"
              right
              transition="slide-x-transition"
              close-on-content-click
              width="1200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card class="flex d-flex flex-column" link v-bind="attrs" v-on="on" outlined>
                  <v-card-title></v-card-title>
                  <v-row align="center"
                    ><v-card-text class="text-center">
                      <v-icon size="48" color="EoleBlue">mdi-plus-circle</v-icon>
                      <p>Nouvelle demande</p>
                    </v-card-text>
                  </v-row>
                </v-card>
              </template>

              <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                <v-card-text style="background-color: #f1f4f9">
                  <v-row>
                    <v-col cols="12" md="3" class="no-padding ml-n2">
                      <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                        <v-card-title>
                          <v-img
                            :src="require('@/assets/images/logos/anavel_logo.png')"
                            max-height="150px"
                            max-width="120px"
                            alt="logo"
                            contain
                          ></v-img>
                        </v-card-title>
                        <v-card-title class="text-center justify-center muller-capitalized"
                          >Mes Services Assurance</v-card-title
                        >
                        <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                        <v-card-text class="no-padding">
                          <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                          </v-img>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="9" class="no-padding d-flex">
                      <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                        <v-card-title>
                          <span class="text-h5">Effectuer une nouvelle demande de congé</span>
                        </v-card-title>
                        {{ numDays }}
                        <v-card-text>
                          <v-date-picker
                            :allowed-dates="allowedDates"
                            v-model="newHoliday.date"
                            range
                            locale="fr"
                            full-width
                            style="height: 25rem"
                            show-week
                            :first-day-of-week="1"
                            @update:picker-date="pickerUpdate($event)"
                            :title-date-format="setTitleDateFormat"
                          >
                            <template> </template>
                          </v-date-picker>
                          <v-row class="mt-5">
                            <v-col cols="12" md="12">
                              <v-select
                                v-model="selectedType"
                                outlined
                                dense
                                :items="items"
                                item-text="label"
                                item-value="id"
                                label="Type de congé"
                              >
                                <template v-slot:item="{ item }">
                                  <v-list-item-content>
                                    <v-list-item-title>({{ item.type }}) {{ item.label }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row class="mt-n5">
                            <v-col cols="12" md="12">
                              <v-textarea
                                outlined
                                dense
                                v-model="newHoliday.comment"
                                label="Éventuel commentaire"
                                rows="2"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                          <v-row class="mt-n5" v-if="selectedType === 1 || selectedType === 2 || selectedType === 3">
                            <v-col cols="12" md="4">
                              <v-text-field
                                v-model="calculateDay"
                                readonly
                                label="Nombre de jours disponible"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                readonly
                                v-model="numDays"
                                label="Nombre de jours demandés"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                v-if="calculateDay - numDays >= 0"
                                readonly
                                :value="convertNumDaysAndCalculateDay"
                                label="Solde après la demande"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="mt-n10">
                            <v-col cols="12" md="12">
                              <v-switch inset label="Période estivale" v-model="newHoliday.isSummer"></v-switch>
                            </v-col>
                            <v-col cols="12" md="12" v-if="allowHalfDay" class="mt-n10">
                              <v-switch inset label="Demi-journée" v-model="newHoliday.isHalfDay"></v-switch>
                            </v-col>
                            <v-col cols="12" md="12" v-if="newHoliday.isHalfDay" class="mt-n10">
                              <v-radio-group v-model="newHoliday.halfDayType">
                                <v-radio
                                  v-for="n in [
                                    { id: 1, label: 'Matin' },
                                    { id: 2, label: 'Après-midi' },
                                  ]"
                                  :key="n.id"
                                  :label="`${n.label}`"
                                  :value="n.id"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                          <v-card-text
                            class="EoleError--text text-center"
                            v-if="
                              calculateDay - numDays < 0 ||
                              (selectedType !== 1 && selectedType !== 2 && selectedType !== 3)
                            "
                          >
                            Impossible de prendre ce congés il ne vous reste que {{ calculateDay }} jours. Vous devez
                            poser le nombre de jours maximum possible dans cette demande et compléter votre demande avec
                            un autre mode de congés disponible.
                          </v-card-text>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-if="selectedType === 1 || selectedType === 2 || selectedType === 3"
                                v-on="on"
                                v-bind="attrs"
                                :disabled="
                                  calculateDay - numDays < 0 ||
                                  newHoliday.date.length < 2 ||
                                  !selectedType ||
                                  numDays == 0
                                "
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                              <v-btn
                                v-else
                                :disabled="newHoliday.date.length < 2 || !selectedType"
                                v-on="on"
                                v-bind="attrs"
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                            </template>
                            <span>Cliquez-ici pour valider votre demande de congé</span>
                          </v-tooltip>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="12">
            <v-dialog
              v-model="dialogRulesHoliday"
              right
              transition="slide-x-transition"
              close-on-content-click
              width="1200px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card class="flex d-flex flex-column" link v-bind="attrs" v-on="on" outlined>
                  <v-card-title></v-card-title>
                  <v-row align="center"
                    ><v-card-text class="text-center">
                      <v-icon size="48" color="EoleBlue">mdi-file</v-icon>
                      <p>Règles congés</p>
                    </v-card-text>
                  </v-row>
                </v-card>
              </template>

              <v-card>
                <v-card-title>RÈGLES DE FONCTIONNEMENT DES CONGÉS, REPOS ET ABSENCES DANS L’ENTREPRISE</v-card-title>
                <v-card-text>
                  <p>
                    Chaque collaborateur bénéficie de jours de congés payés définis contractuellement (25 jours par an,
                    soit 2.083 jours par mois travaillé). En fonction de la nature de votre contrat de travail, vous
                    bénéficiez :
                  </p>
                  <ul>
                    <li>De 5 jours complémentaires au titre de l'accord sur le temps de travail (ok la dessus ?) Ou</li>
                    <li>
                      D'un nombre de jours forfaitaires compensatoires redéfinis chaque année sur une base de 215 jours
                      de travail annuel.
                    </li>
                  </ul>
                  <p>Prise du congé principal :</p>
                  <p>
                    La convention collective prévoit la prise de 4 semaines de congés entre le 1er mai et le 31 octobre
                    de chaque année avec au minimum une période de 2 semaines consécutives et l'obtention de jours de
                    fractionnement complémentaires si l'employeur demandait à un collaborateur de ne pas poser ses 4
                    semaines dans cette période.
                  </p>
                  <p>
                    Outre la période estivale (définie ci-dessous), le souhait de l’entreprise est d’offrir un maximum
                    de flexibilité aux collaborateurs. Ainsi, le reliquat de congés, de jours de récupération du temps
                    de travail ou de jours forfait compensatoires, peut être posé librement en journées complètes où
                    demi-journées en respectant les règles suivantes :
                  </p>
                  <ul>
                    <li>
                      Chaque demande de jours de congés doit être réalisée au moins 15 jours avant la date souhaitée et
                      ce afin de ne pas désorganiser le service et l’entreprise.
                    </li>
                    <li>
                      Le manager dispose d’un délai de 7 jours pour valider la demande et à défaut le système la
                      validera automatiquement et la demande sera considérée comme validée.
                    </li>
                  </ul>
                  <p>Demandes de congés en période estivale :</p>
                  <ul>
                    <li>
                      La campagne de demandes de congés d’été commence le 1er Janvier de chaque année et un mail
                      d’information vous sera transmis à cet effet.
                    </li>
                    <li>
                      L’ensemble des collaborateurs doit avoir réalisé ses demandes de congés d’été avant le 31 mars de
                      chaque année.
                    </li>
                    <li>
                      Les managers disposeront d’un délai d’un mois pour valider les demandes (au maximum jusqu’au 30
                      Avril) et à défaut de validation où de refus par le manager dans le système, les demandes de
                      congés seront considérées comme validées.
                    </li>
                    <li>
                      Si les demandes de congés ne sont pas réalisées par le collaborateur entre le 1er mars et le 31
                      mars, ce dernier se verra imposer ses congés en fonction des besoins du service et de la société.
                    </li>
                  </ul>
                  <p>Périodes spécifiques imposées par la société ANAVEL :</p>
                  <p>Congés estivaux : 1er juin au 31 août :</p>
                  <ul>
                    <li>Obligation de poser 3 semaines dans cette période dont au minimum 2 semaines consécutives.</li>
                    <li>
                      Les managers recommandent qu’une discussion préalable soit réalisée entre les collaborateurs avant
                      la réalisation des demandes dans le système pour permettre la bonne continuité de l’activité de
                      l’entreprise. Si aucun accord ne pouvait être réalisé entre les collaborateurs, l’entreprise
                      appliquerait les règles de fonctionnement de priorité établies dans la convention collective.
                    </li>
                  </ul>
                  <p>Absences pour maladie :</p>
                  <p>
                    En cas d'absence pour maladie, les collaborateurs ont l’obligation de prévenir le manager et le
                    responsable ressource humaine par mail et de transmettre au maximum dans les 48 heures l’arrêt de
                    travail correspondant. À défaut, cela sera considéré comme une absence injustifiée.
                  </p>
                  <p>Congés pour événements familiaux :</p>
                  <p>
                    Chaque collaborateur peut en supplément bénéficier de congés accordés par la convention collective
                    dans les cas suivants. Les demandes effectuées dans ce cadre doivent, dans la mesure du possible
                    (pour les cas de figure qui le permettent), respecter les mêmes délais que les demandes de congés
                    traditionnelles.
                  </p>
                  <ul>
                    <li>mariage (ou remariage) de l'employé, 5 jours ouvrés ;</li>
                    <li>conclusion d'un Pacs, 5 jours ouvrés ;</li>
                    <li>mariage d'un enfant, 2 jours ouvrés ;</li>
                    <li>
                      mariage dans la proche famille (père, mère, beau-père, belle-mère, frère, sœur), 1 jour ouvré ;
                    </li>
                    <li>décès du conjoint, du concubin, du partenaire d'un Pacs, 6 jours ouvrés ;</li>
                    <li>décès des père, mère, beau-père, belle-mère, 3 jours ouvrés ;</li>
                    <li>décès d'un autre ascendant du salarié ou de son conjoint, 1 jour ouvré ;</li>
                    <li>décès d'un frère ou d'une sœur, 3 jours ouvrés ;</li>
                    <li>décès d'un enfant, 6 jours ouvrés ;</li>
                    <li>naissance d'un enfant ou arrivée d'un enfant placé en vue de son adoption, 3 jours ouvrés ;</li>
                    <li>annonce de la survenue d'un handicap chez un enfant : 5 jours ouvrés.</li>
                    <li>déménagement du salarié, 1 jour ouvré, une fois par an.</li>
                  </ul>
                  <p>Autres causes d’absences :</p>
                  <p>
                    Dans les autres cas de figure, veuillez-vous rapprocher de votre manager ou de votre responsable
                    ressource humaine.
                  </p>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="holidays"
          :headers="headersHolidays"
          hide-default-footer
          :no-data-text="`Aucun congés de pris depuis votre arrivé dans l'entreprise`"
          :items-per-page="-1"
        >
          <template v-slot:[`item.period`]="{ item }">
            <span v-if="!item.is_half_day">Du {{ item.start_date_format }} au {{ item.end_date_format }}</span>
            <span v-else-if="item.is_half_day && item.half_day_type === 1">Le {{ item.start_date_format }} Matin</span>
            <span v-else-if="item.is_half_day && item.half_day_type === 2"
              >Le {{ item.start_date_format }} Après-midi</span
            >
          </template>
          <template v-slot:[`item.number_of_days`]="{ item }">
            <span>{{ item.number_of_days }}</span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip small v-if="item.state === 1" color="EoleBlue">A approuver</v-chip>
            <v-chip small v-else-if="item.state === 2" color="EoleError"
              ><v-icon left small>mdi-close-thick</v-icon>Refusé</v-chip
            >
            <v-chip small v-else color="EoleGreen" outlined><v-icon left small>mdi-check</v-icon> Validé</v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  v-if="item.state === 1"
                  @click="cancelHoliday(item.id)"
                  elevation="0"
                  color="EoleError"
                  class="white--text"
                  icon
                  fab
                  ><i class="fa-solid fa-ban" style="font-size: 1.1rem"></i
                ></v-btn>
              </template>
              <span>Annuler la demande</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-badge color="EoleError" dot v-if="item.state === 2" overlap>
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="openResum(item)"
                    elevation="0"
                    color="EoleBlue"
                    class="white--text"
                    icon
                    fab
                    ><i class="fa-solid fa-comment" style="font-size: 1.1rem"></i
                  ></v-btn>
                </v-badge>
              </template>
              <span>Voir l'objet du refus</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <v-dialog
          v-model="dialogResumHoliday"
          right
          transition="slide-x-transition"
          close-on-content-click
          width="1200"
          @click:outside="
            () => {
              dialogResumHoliday = false
              selectedItem = {}
            }
          "
        >
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Résumé</span>
                    </v-card-title>
                    <v-card-text>
                      <v-textarea
                        outlined
                        dense
                        v-model="selectedItem.manager_comment"
                        label="Commentaire de votre manager"
                        rows="2"
                      ></v-textarea>
                    </v-card-text>

                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import config from "@/views/config/config"

import moment from "moment"
import "moment-business-days"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")

import Holidays from "date-holidays"
import { getCookie } from "@/utils/cookies"

const hd = new Holidays()
hd.init("FR")

export default {
  name: "HolidayView",
  created() {
    this.getPublicHolidaysFrance()
    const array = this.getPublicHolidaysFrance()
    moment.updateLocale("fr", {
      holidays: array,
      holidayFormat: "YYYY-MM-DD",
      workingWeekdays: [1, 2, 3, 4, 5],
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY LT",
        LLLL: "dddd D MMMM YYYY LT",
      },
    })
    this.fetchAccountHoliday()
    this.verifyCurrentDate()
  },

  data() {
    return {
      msgYear: "",
      msgNextYear: "",
      selectedItem: {},
      dialogResumHoliday: false,
      daysOfLastMonth: [],
      lastMonthPublicHolidays: [],
      publicHolidays: [],
      editedMonth: 0,
      dataWorkedDays: [],
      workingDays: 0,
      numberOfDaysWorks: 0,
      modifyLeave: {},
      dialogModify: false,
      dialogModifyPost: false,
      allowHalfDay: false,
      dialogRulesHoliday: false,
      service: {},
      totalAbsentDays: 0,
      totalWorkingDays: 0,
      totalNumberWork: 0,
      isDataLoaded: false,
      modifyArray: [],
      leaves: [],
      daysOfMonth: [], // Jours du mois
      dialogVisible: [],
      selectedType: 1,
      items: [
        { id: 1, type: "COP", label: "Congés payés" },
        { id: 4, type: "COEF", label: "Événement familial" },
        { id: 5, type: "MAL", label: "Maladie" },
        { id: 6, type: "CSS", label: "Congés sans solde" },
        { id: 7, type: "MP", label: "Maladie professionnelle" },
        { id: 8, type: "AT", label: "Accident du travail" },
        { id: 9, type: "MAT", label: "Maternité" },
        { id: 10, type: "PAT", label: "Paternité" },
        { id: 11, type: "ABNJ", label: "Absence non justifiée" },
        { id: 12, type: "REA", label: "Révision examen apprenti" },
        { id: 13, type: "TT", label: "Télétravail" },
        { id: 14, type: "ABJR", label: "Absence justifiée rémunurée" },
        {
          id: 15,
          type: "JT",
          label: "Jour travaillé",
        },
      ],
      contract: {},
      dialogPostHoliday: false,
      beforeCurrentMonth: moment().subtract(1, "month").format("MMMM YYYY"),
      remain: {
        // paidLeave: 25,
        // compensatoryPackageLeave: 5,
        // recoveryOfWorkingTimeLeave: 5,
      },

      availableDates: [],
      holidays: [],
      newHoliday: {
        date: [],
        type: "",
        days: 0,
        comment: "",
      },
      testData: {
        current: 10,
        test: "",
      },
      headersHolidays: [
        { text: "Type", value: "type" },
        { text: "Période", value: "period" },
        { text: "Nombre de jours", value: "number_of_days" },
        { text: "Statut", value: "status" },
        { text: "Date de la demande", value: "date" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      year: moment().year(), // Ajout de l'année actuelle
      month: moment().month() + 1, // Ajout du mois actuel
      now: moment().format("Do MMMM YYYY"),
      suggestedLeave: {},
      lastYear: moment().subtract(1, "year").format("YYYY"),
      nextYear: moment().add(1, "year").format("YYYY"),
    }
  },

  computed: {
    workedDays() {
      let total = 0
      let lastMonth = parseInt(this.editedMonth) + 1
      this.leaves.forEach(leave => {
        let month = moment(leave.start_date).format("MM")

        if (month == lastMonth) {
          if (leave.type === "TT") {
            total += 0
          } else {
            total += leave.number_of_days
          }
        }
      })

      return this.totalNumberWork - total
    },
    absentDays() {
      let total = 0
      let lastMonth = parseInt(this.editedMonth) + 1
      this.leaves.forEach(leave => {
        let month = moment(leave.start_date).format("MM")

        if (month == lastMonth) {
          if (leave.type === "TT") {
            total += 0
          } else {
            total += leave.number_of_days
          }
        }
      })

      return total
    },

    calculateDay() {
      let result = 0 // Initialisation de la variable résultat
      switch (this.selectedType) {
        case 1:
          result = parseFloat(
            parseFloat(this.remain.remain_paid_leave_last_year) +
              parseFloat(this.remain.remain_paid_leave) -
              this.getRemainPaidDemand(),
          )
          break
        case 2:
          result = parseFloat(this.remain.recovery_working_time_leave - this.getRecoveryDemand())
          break
        case 3:
          result = parseFloat(this.remain.compensatory_package_leave - this.getRecoveryDemand())
          break
        default:
          result = 0
      }
      return result.toFixed(2) // Utilisation de toFixed(2) pour formater le résultat avec deux décimales
    },
    getFirstDayOfCurrentMonth() {
      return moment().startOf("month").format("Do MMMM YYYY")
    },

    convertNumDaysAndCalculateDay() {
      return (this.calculateDay - this.numDays).toFixed(2)
    },

    numDays() {
      if (this.newHoliday.date.length > 1) {
        const startDate = moment(this.newHoliday.date[0])
        const endDate = moment(this.newHoliday.date[1])

        if (startDate.isSame(endDate, "day")) this.allowHalfDay = true
        else this.allowHalfDay = false

        if (this.newHoliday.isHalfDay) return 0.5

        return this.calculateWorkdays(startDate, endDate)
      }
    },
  },
  mounted() {
    this.updateCalendar()
  },
  methods: {
    verifyCurrentDate() {
      // Obtenez la date actuelle formatée en "YYYY-MM-DD"
      const currentDate = moment().format("YYYY-MM-DD")

      // Définissez la date de comparaison (1er juin de l'année actuelle)
      const firstOfJune = moment().startOf("year").month(5).date(1).format("YYYY-MM-DD")

      // Comparez les dates
      if (moment(currentDate).isSameOrAfter(firstOfJune)) {
        this.msgYear = `01/06/${moment().format("YYYY")} au 31/05/${moment().add(1, "year").format("YYYY")}`
        this.msgNextYear = `01/06/${moment().add(1, "year").format("YYYY")} au 31/05/${moment()
          .add(2, "year")
          .format("YYYY")}`
      } else {
        this.msgYear = `01/06/${moment().subtract(1, "year").format("YYYY")} au 31/05/${moment().format("YYYY")}`
        this.msgNextYear = `01/06/${moment().format("YYYY")} au 31/05/${moment().add(1, "year").format("YYYY")}`
      }
    },
    openResum(item) {
      this.selectedItem = item
      this.dialogResumHoliday = true
    },
    async cancelHoliday(idAccountHoliday) {
      const idAccount = this.$store.state.user.id

      try {
        fetch(`${config.apiUri}/accounts/${idAccount}/holidays/${idAccountHoliday}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
          .then(response => {
            if (response.ok) {
              this.$toast.success("Vous avez annulé la demande de congés", {
                position: "bottom-right",
              })
              this.$emit("update")
            } else {
              this.$toast.error("Erreur", {
                position: "bottom-right",
              })
            }
          })
          .finally(() => {
            this.fetchAccountHoliday()
          })
      } catch (err) {
        console.log(err)
      }
    },
    getRecoveryDemand() {
      let total = 0
      this.leaves.forEach(leave => {
        if (this.contract.id == 4 && leave.type === "(RTT)" && leave.state === 1) {
          total += leave.number_of_days
        }

        if (this.contract.id == 2 && leave.type === "(JFC)" && leave.state === 1) {
          total += leave.number_of_days
        }
      })

      return total
    },
    getRemainPaidDemand() {
      let total = 0
      this.leaves.forEach(leave => {
        if (leave.type === "(COP)" && leave.state === 1) {
          total += leave.number_of_days
        }
      })

      return total
    },
    calculateNumberOfDays() {
      const monthOfInterest = moment().subtract(1, "month").format("MM")
      const lastMonth = moment().subtract(1, "month").format("MM")
      let total = 0
      const totalDays = this.leaves.reduce((acc, leave) => {
        const month = leave.start_date.split("-")[1]
        if (month === monthOfInterest) {
          total += leave.number_of_days
        }
        this.numberOfDaysWorks = total
      }, 0)
    },
    getHalfDayClass(day) {
      const leave = this.leaves.find(leave => moment(day).isBetween(leave.start_date, leave.end_date, "day", "[]"))

      if (!leave) return ""

      if (leave.half_day_type === 2) {
        return "morning"
      } else if (leave.half_day_type === 1) {
        return "afternoon"
      }

      return halfDayClass
    },
    getHalf(day) {
      const leave = this.leaves.find(leave => moment(day).isBetween(leave.start_date, leave.end_date, "day", "[]"))

      if (!leave) return false

      return leave.is_half_day
    },
    suggestModifyPost(day) {
      this.dialogModifyPost = true

      this.suggestedLeave = {
        start_date: day,
        end_date: day,
        is_half_day: 0,
        half_day_type: 0,
      }
    },
    suggestModify(day) {
      //seqrch in leave if day === start_date or end_date
      this.dialogModify = true
      let leave = this.leaves.find(leave => moment(day).isBetween(leave.start_date, leave.end_date, "day", "[]"))

      this.modifyLeave = { ...leave }
      this.modifyLeave.day = day
    },
    confirmModify(day) {
      //put in db
      this.dialogModify = false

      let leave = this.leaves.find(leave => moment(day).isBetween(leave.start_date, leave.end_date, "day", "[]"))
      leave.type = this.modifyLeave.type

      this.calculateNumberOfDays()
    },
    confirmModifyPost() {
      //put in db
      this.dialogModifyPost = false

      this.leaves.push({
        start_date: this.suggestedLeave.start_date,
        end_date: this.suggestedLeave.end_date,
        type: this.modifyLeave.type,
        is_half_day: this.suggestedLeave.is_half_day === true ? 1 : 0,
        half_day_type: this.suggestedLeave.half_day_type,
        number_of_days: this.suggestedLeave.is_half_day === true ? 0.5 : 1,
        state: 1,
      })

      this.calculateNumberOfDays()
    },
    getHolidayType(day) {
      const leave = this.leaves.find(leave => moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"))

      return leave.type
    },

    getHolidayInformation(day) {
      const leave = this.leaves.find(leave => moment(day).isBetween(leave.start_date, leave.end_date, "day", "[]"))
      if (!leave) {
        return "working"
      }
      return leave.type.split(" ")[0]
    },
    openDialog(item) {
      // Méthode pour ouvrir le v-dialog pour un élément donné
      this.selectedItem = item
      this.dialogAction = true
    },
    async putHoliday(id, answer) {
      const idAgency = this.$store.state.user.agency_id
      try {
        const formData = new FormData()

        formData.append("idHoliday", id)
        answer === 2 ? formData.append("place", "refuse") : formData.append("place", "accept")
        formData.append("comments", this.comments)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez modifié la demande de congés", {
              position: "bottom-right",
            })

            this.$emit("update")

            this.dialogAction = false

            this.comments = ""
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },

    updateCalendar() {
      this.generateDaysOfMonth()
      this.processLeaves()
      this.isDataLoaded = true
    },
    getLastMonthPublicHolidaysFrance(year) {
      this.lastMonthPublicHolidays = []

      const frenchHolidays = hd.getHolidays(year)

      frenchHolidays.forEach(holiday => {
        holiday.date = moment(holiday.date).format("YYYY-MM-DD")

        this.lastMonthPublicHolidays.push(holiday.date)
      })

      console.log(this.lastMonthPublicHolidays)
    },
    generateDaysOfLastMonth(month) {
      let year = this.year
      this.daysOfLastMonth = []

      // Correction du calcul du mois précédent
      // et de l'année, spécialement pour janvier.

      // Appel pour obtenir les jours fériés du mois précédent
      this.getLastMonthPublicHolidaysFrance(year) // Utilisation de la bonne année corrigée

      console.log(month, year)

      let lastMonth = month // Correction pour l'indexation à partir de 0
      let startOfMonth = moment([year, lastMonth]).startOf("month")
      let endOfMonth = moment([year, lastMonth]).endOf("month")
      console.log(startOfMonth, endOfMonth)

      while (startOfMonth.isSameOrBefore(endOfMonth)) {
        console.log("proute")
        if (startOfMonth.isBusinessDay() && !this.lastMonthPublicHolidays.includes(startOfMonth.format("YYYY-MM-DD"))) {
          this.daysOfLastMonth.push(startOfMonth.clone())
          this.totalNumberWork++ // Assurez-vous que cette propriété est initialisée quelque part
        }
        startOfMonth.add(1, "days")
      }
    },
    generateDaysOfMonth(month) {
      // this.getPublicHolidaysFrance(this.year)

      this.daysOfMonth = []
      let startOfMonth = moment([this.year, month - 1]).startOf("month")
      let endOfMonth = moment([this.year, month - 1]).endOf("month")

      while (startOfMonth.isSameOrBefore(endOfMonth)) {
        if (startOfMonth.isBusinessDay() && !this.publicHolidays.includes(startOfMonth.format("YYYY-MM-DD"))) {
          this.daysOfMonth.push(startOfMonth.clone())
          this.totalNumberWork++
        }
        startOfMonth.add(1, "days")
      }
    },
    getLeaveClass(day) {
      const leave = this.leaves.find(leave => moment(day).isBetween(leave.start_date, leave.end_date, "day", "[]"))

      if (leave) {
        this.days = leave.days
        this.type = leave.type
        if (leave.is_half_day === 1) {
          if (leave.half_day_type === 1) {
            if (leave.state === 3) {
              return "leave-half-morning-validated-modify"
            } else {
              return "leave-half-morning-pending-modify"
            }
          }

          // Si c'est un demi-jour l'après-midi
          if (leave.half_day_type === 2) {
            if (leave.state === 3) {
              return "leave-half-afternoon-validated-modify"
            } else {
              return "leave-half-afternoon-pending-modify"
            }
          }
        }
        // Sinon, retournez les classes normales en fonction de 'state'
        if (moment(day).isSame(leave.start_date, "day") && leave.state === 1) {
          return "leave-start-nok"
        }
        if (moment(day).isSame(leave.end_date, "day") && leave.state === 1) {
          return "leave-end-nok"
        }
        if (moment(day).isSame(leave.start_date, "day") && leave.state === 3) {
          return "leave-start"
        }
        if (moment(day).isSame(leave.end_date, "day") && leave.state === 3) {
          return "leave-end"
        }
        if (leave.state === 3) {
          return "leave-middle"
        }
        if (leave.state === 1) {
          return "leave-middle-nok"
        }
      }

      if (!leave) return "working"
    },
    processLeaves() {
      this.leaves = this.holidays
        .filter(holiday => {
          let startDate = moment(holiday.start_date)
          let endDate = moment(holiday.end_date)

          return startDate.month() === this.month - 1 || endDate.month() === this.month - 1
        })
        .map(holiday => ({
          collaboratorId: holiday.id_account,
          startDate: moment(holiday.start_date).format("YYYY-MM-DD"),
          endDate: moment(holiday.end_date).format("YYYY-MM-DD"),
          days: parseInt(holiday.number_of_days),
          state: parseInt(holiday.state),
          isHalfDay: parseInt(holiday.is_half_day),
          halfDayType: parseInt(holiday.half_day_type),
          type: holiday.type,
        }))
    },
    async fetchAccountHoliday() {
      this.holidays = []
      this.dataWorkedDays = []

      const idAccount = this.$store.state.user.id

      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/holidays`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        const data = await response.json()

        console.log(data)

        this.holidays = data.holidays
        this.remain = data.remaining

        console.log(this.remain)

        this.remain = {
          remain_paid_leave: this.remain.remain_paid_leave,
          remain_paid_leave_last_year: this.remain.remain_paid_leave_last_year,
          compensatory_package_leave: this.remain.compensatory_package_leave,
          recovery_working_time_leave: this.remain.recovery_working_time_leave,
        }

        this.service = data.service

        this.contract = data.contract

        this.holidays.forEach(holiday => {
          holiday.start_date_format = moment(holiday.start_date).format("DD/MM/YYYY")
          holiday.end_date_format = moment(holiday.end_date).format("DD/MM/YYYY")
          holiday.state = parseInt(holiday.state)
          holiday.is_half_day = parseInt(holiday.is_half_day)
          holiday.half_day_type = parseInt(holiday.half_day_type)
          holiday.number_of_days = parseFloat(holiday.number_of_days)
          holiday.date = moment(holiday.date).format("DD/MM/YYYY")
        })

        console.log(this.contract)
        if (this.contract.id == 2) {
          this.items.push({ id: 3, type: "JFC", label: "Jours forfait compensatoire" })
        } else if (this.contract.id == 4) {
          this.items.push({ id: 2, type: "RTT", label: "Récupération du temps de travail" })
        }

        if (data.worked_days.length > 0) {
          this.dataWorkedDays = data.worked_days
          this.dialogVisible = this.dataWorkedDays.map(() => false)
        }

        this.dataWorkedDays.forEach(workedDay => {
          workedDay.state = parseInt(workedDay.state)
          workedDay.displayMonth = this.getMonthByNumber(workedDay.month)
        })

        // order items by id
        this.items.sort((a, b) => a.id - b.id)

        this.leaves = this.holidays
      } catch (err) {
        console.log(err)
      }
    },
    getMonthByNumber(number) {
      return moment().month(number).format("MMMM")
    },
    async postDaysWork() {
      let lastMonthLeaves = this.leaves.filter(leave => {
        let startDate = moment(leave.start_date)
        let endDate = moment(leave.end_date)
        return startDate.month() == this.editedMonth || endDate.month() == this.editedMonth
      })

      const postData = {
        days_work: this.workedDays,
        days: lastMonthLeaves.length > 0 ? JSON.stringify(lastMonthLeaves) : JSON.stringify([{}]),
        month: this.editedMonth,
      }

      const idAccount = this.$store.state.user.id

      try {
        fetch(`${config.apiUri}/accounts/${idAccount}/day-works`, {
          method: "POST",
          body: JSON.stringify(postData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
          .then(response => {
            if (response.ok) {
            }
          })
          .finally(() => {
            this.fetchAccountHoliday()

            this.$toast.success("Merci pour la validation de vos jours", {
              position: "bottom-right",
            })

            this.$forceUpdate()
            this.$emit("update")
          })
      } catch (err) {
        console.log(err)
      }
      this.dialogVisible = false
    },
    async postHoliday() {
      const idAccount = this.$store.state.user.id

      const postData = {
        numberOfDays: this.numDays,
        startDate: this.newHoliday.date[0],
        endDate: this.newHoliday.date[1],
        comments: this.newHoliday.comment,
        type: `(${this.items.find(item => item.id === this.selectedType).type})`,
        subType: this.selectedType,
        isHalfDay: this.newHoliday.isHalfDay ? 1 : 0,
        halfDayType: this.newHoliday.halfDayType ? this.newHoliday.halfDayType : 0,
        isSummer: this.newHoliday.isSummer ? 1 : 0,
        emailInformations: {
          to_name: this.service.firstname_manager,
          firstname_collaborator: this.$store.state.user.firstname,
          start_date_display: moment(this.newHoliday.date[0]).format("DD/MM/YYYY"),
          end_date_display: moment(this.newHoliday.date[1]).format("DD/MM/YYYY"),
          to_email: this.service.email_manager,
          email_collaborator: this.$store.state.user.email,
        },
      }

      try {
        fetch(`${config.apiUri}/accounts/${idAccount}/holidays`, {
          method: "POST",
          body: JSON.stringify(postData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
          .then(response => {
            if (response.ok) {
              this.$toast.success("Votre demande de congés a bien été prise en compte", {
                position: "bottom-right",
              })
            } else {
              this.$toast.error("Erreur", {
                position: "bottom-right",
              })
            }
          })
          .finally(() => {
            this.dialogPostHoliday = false
            this.newHoliday = {
              date: [],
              type: "",
              days: 0,
            }

            this.fetchAccountHoliday()
          })
      } catch (err) {
        console.log(err)
      }
    },
    // postHoliday() {
    //   this.remain.paidLeave = this.remain.paidLeave - this.numDays
    //   this.holidays.push({
    //     start_date: this.newHoliday.date[0],
    //     end_date: this.newHoliday.date[1],
    //     start_date_format: moment(this.newHoliday.date[0]).format("DD/MM/YYYY"),
    //     end_date_format: moment(this.newHoliday.date[1]).format("DD/MM/YYYY"),
    //     number_of_days: this.numDays,
    //     status: 1,
    //     type: this.newHoliday.type,
    //     comment: "test",
    //   })

    //   this.newHoliday = {
    //     date: [],
    //     type: "",
    //     days: 0,
    //   }

    //   this.dialogPostHoliday = false
    // },
    getPublicHolidaysFrance(year) {
      this.publicHolidays = []

      const frenchHolidays = hd.getHolidays(year)

      frenchHolidays.forEach(holiday => {
        holiday.date = moment(holiday.date).format("YYYY-MM-DD")

        this.publicHolidays.push(holiday.date)
      })
    },
    allowedDates(a) {
      return this.availableDates.includes(a)
    },
    pickerUpdate: function (val) {
      // this.getPublicHolidaysFrance()

      this.getPublicHolidaysFrance(moment(val, "YYYY-MM").format("YYYY"))
      const startDate = moment(val, "YYYY-MM").startOf("month")
      const endDate = moment(val, "YYYY-MM").endOf("month")

      let availableDates = []

      for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, "day")) {
        if (
          date.isBusinessDay() && // Vérifiez si c'est un jour ouvrable (pas samedi, dimanche, ni jour férié)
          !this.holidays.includes(date.format("YYYY-MM-DD")) &&
          !this.publicHolidays.includes(date.format("YYYY-MM-DD"))
        ) {
          availableDates.push(date.format("YYYY-MM-DD"))
        }
      }

      this.availableDates = availableDates
      this.allowedDates()
    },
    calculateWorkdays(startDate, endDate) {
      let workdays = 0
      for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, "day")) {
        if (
          date.isBusinessDay() && // Vérifiez si c'est un jour ouvrable (pas samedi, dimanche, ni jour férié)
          !this.holidays.includes(date.format("YYYY-MM-DD")) &&
          !this.publicHolidays.includes(date.format("YYYY-MM-DD"))
        ) {
          workdays++
        }
      }
      this.workingDays = workdays
      return workdays
    },

    // getFirstDayOfCurrentMonth() {
    //   return moment().startOf("month").format("YYYY-MM-DD")
    // },

    setTitleDateFormat(isoDate) {
      if (isoDate.length > 1) {
        return `Du ${moment(isoDate[0]).format("DD/MM/YYYY")} au ${moment(isoDate[1]).format("DD/MM/YYYY")} (${
          this.numDays
        } jours)`
      } else if (isoDate.length === 1) {
        return `Choisissez une date de fin`
      } else {
        return `Choisissez le début de la période`
      }
    },
  },
}
</script>

<style>
.theme--light.v-date-picker-header
  .v-date-picker-header__value:not(.v-date-picker-header__value--disabled)
  button:not(:hover):not(:focus) {
  color: #fcc03c !important;
}
.morning {
  position: relative;
  bottom: 110px;
}
.afternoon {
  position: relative;
  top: 30px;
}
.working {
  padding-top: 20px !important;
}

.v-date-picker-table {
  width: 100% !important;
  height: 320px;
}

.v-date-picker-title__date {
  font-size: 1rem !important;
}

.no-padding {
  padding: 0 !important;
}

/* Style pour la moitié supérieure d'une cellule de congé demi-journée le matin - Validé */
.leave-half-morning-validated-modify {
  background: linear-gradient(to bottom, #001f47 50%, transparent 50%);
  content: "AM";
}

.leave-half-morning-validated-modify .row {
  padding-bottom: 95px;
}

/* Style pour la moitié supérieure d'une cellule de congé demi-journée le matin - En attente */
.leave-half-morning-pending-modify {
  background: linear-gradient(to bottom, #fcc03c 50%, transparent 50%);
  content: "AM";
}

.leave-half-morning-pending-modify .row {
  padding-bottom: 40px;
}

/* Style pour la moitié inférieure d'une cellule de congé demi-journée l'après-midi - Validé */
.leave-half-afternoon-validated-modify {
  background: linear-gradient(to top, #001f47 50%, transparent 50%);
}

.leave-half-afternoon-validated-modify .row {
  padding-top: 120px;
}

/* Style pour la moitié inférieure d'une cellule de congé demi-journée l'après-midi - En attente */
.leave-half-afternoon-pending-modify {
  background: linear-gradient(to top, #fcc03c 50%, transparent 50%);

  content: "PM";
}

.leave-half-afternoon-pending-modify .row {
  padding-top: 80px;
}

.calendar {
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  margin: auto;
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

th,
td {
  border: 1px solid #e0e0e0;
  padding: 8px 16px;
  text-align: center;
}

thead th {
  background-color: #f5f5f5;
  color: #757575;
}

tbody tr:nth-child(odd) {
  background-color: #fafafa;
}

tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.on-leave {
  color: #f44336; /* Couleur rouge pour les jours de congé */
}

th:first-child,
td:first-child {
  text-align: left;
}

.leave-start-nok,
.leave-middle-nok,
.leave-end-nok {
  background-color: #fcc03c; /* Couleur pour les congés */
}

.leave-start-nok {
  border-right: none; /* Supprime la bordure droite */
  padding-top: 20px !important;
}

.leave-end-nok {
  border-left: none; /* Supprime la bordure gauche */
  padding-top: 20px !important;
}

.leave-middle-nok {
  border-left: none; /* Supprime les bordures gauche et droite */
  border-right: none;
  padding-top: 20px !important;
}

/* ... Autres styles ... */

/* Style de base pour les cellules de congé */
.leave-start,
.leave-middle,
.leave-end {
  background-color: #001f47; /* Couleur pour les congés */
}

/* Bordures arrondies pour le début et la fin */
.leave-start {
  border-right: none; /* Supprime la bordure droite */
}

.leave-end {
  border-left: none; /* Supprime la bordure gauche */
}

/* Ajustements pour les cellules du milieu */
.leave-middle {
  border-left: none; /* Supprime les bordures gauche et droite */
  border-right: none;
}

/* Style pour la dernière cellule de congé si elle est suivie d'une cellule non-congé */
.leave-end + td {
  border-left: 1px solid #e0e0e0; /* Remet la bordure gauche */
}

/* Style pour la première cellule normale après une série de congés */
td:not(.leave-middle):not(.leave-start):not(.leave-end) {
  border-left: 1px solid #e0e0e0; /* Assure que la cellule a une bordure gauche */
}
</style>
