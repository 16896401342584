<template>
  <div>
    <v-row>
      <v-col cols="12" md="3" class="d-flex flex-column">
        <v-navigation-drawer permanent width="100%">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                <v-row>
                  <v-col cols="12" md="10"> Mes derniers paiements </v-col>
                  <v-col cols="12" md="2" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="toggleAllAmounts" color="primary" fab icon small
                          ><v-icon> {{ showAllAmounts ? "mdi-eye-off" : "mdi-eye" }}</v-icon></v-btn
                        >
                      </template>
                      <span v-if="!showAllAmounts">Afficher les montants des opérations</span>
                      <span v-else>Cacher les montants des opérations</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-list dense>
            <v-list-item-group no-action sub-group>
              <!-- Boucle sur chaque domaine -->
              <template v-for="(pay, index) in pays">
                <v-list-item :key="'pay-' + index">
                  <v-list-item-icon>
                    <v-icon size="16" color="EoleBlue">fa-money-bill-transfer</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ pay.libelle }}</v-list-item-title>
                    <v-list-item-subtitle>Virement réalisé le : {{ pay.date_display }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action class="EoleGreen--text">
                    <span v-if="showAllAmounts">{{ "+" + pay.amount + "€" }}</span>
                    <span v-else class="blurred">{{ "******" }}</span>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>

      <v-col cols="12" md="9" class="d-flex flex-column">
        <v-data-table :items-per-page="-1" :items="documents" :headers="headersPays" hide-default-footer>
          <template v-slot:no-data> Aucune donnée disponible </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon color="EoleError" target="_blank" @click="downloadFile(item.url)">
              <i class="fa-solid fa-file-pdf icon-medium"></i>
            </v-btn>

            <v-btn icon target="_blank" @click="openPreview(item)">
              <i class="fa-solid fa-eye icon-medium"></i>
            </v-btn>
          </template>
          <template v-slot:item.upload_datetime="{ item }">
            {{ getFormatDate(item.upload_datetime) }}
          </template>
        </v-data-table>
        <v-dialog v-model="dialogPreview" max-width="800px">
          <v-card>
            <v-card-text>
              <iframe :src="previewedUrl" frameborder="0" width="100%" height="500px"></iframe>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import config from "@/views/config/config"
import moment from "moment"
import { getCookie } from "@/utils/cookies"

export default {
  name: "Pays",
  data() {
    return {
      pays: [],
      documents: [],
      previewedUrl: null,
      dialogPreview: false,
      selectedPreviewItem: {
        url: "",
      },
      previewSocialStatementDialog: false,
      yearFiltered: moment().year(),
      monthFilter: moment().month(),
      years: [],
      headersPays: [
        { text: "Nom", value: "name" },
        { text: "Date d'enregistrement", value: "upload_datetime" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      showAllAmounts: false, // Variable pour suivre l'état de visibilité de tous les montants
      showAmount: [], // Tableau pour stocker l'état de visibilité de chaque montant
    }
  },
  methods: {
    toggleAmount(index) {
      // Inverser l'état de visibilité du montant pour l'index spécifié
      this.$set(this.showAmount, index, !this.showAmount[index])
    },
    toggleAllAmounts() {
      // Inverser l'état de visibilité de tous les montants
      this.showAllAmounts = !this.showAllAmounts

      // Réinitialiser l'état de visibilité de chaque montant individuel si on cache tous les montants
      if (!this.showAllAmounts) {
        this.showAmount = []
      }

      console.log("showAllAmounts", this.showAllAmounts)
    },
    async getAccountPays() {
      const idAccount = this.$store.state.user.id

      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/pays`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        this.pays = data

        this.pays.forEach(pay => {
          pay.date_display = moment(pay.date).format("DD/MM/yyyy")
        })
      } catch (error) {
        console.error("Error fetching the files:", error)
      }
    },
    async getAccountFiles() {
      const idAccount = this.$store.state.user.id

      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/files/6`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        this.documents = data
      } catch (error) {
        console.error("Error fetching the files:", error)
      }
    },
    async previewFile(path) {
      const idAccount = this.$store.state.user.id
      let url
      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/documents/preview`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            url: path,
          }),
        })
        const data = await response.blob()
        url = URL.createObjectURL(data)
      } catch (error) {
        console.error(error)
      }

      return url
    },
    getFormatDate(date) {
      return moment(date).format("dddd Do MMMM YYYY [à] HH:mm")
    },
    async downloadFile(path) {
      const idAccount = this.$store.state.user.id
      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/documents/preview`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({ url: path }),
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.blob() // Récupère le blob du fichier
        const downloadUrl = URL.createObjectURL(data) // Crée une URL pour le blob

        // Créer un élément <a> pour déclencher le téléchargement
        const a = document.createElement("a")
        a.href = downloadUrl
        a.download = path.split("/").pop() // Utilise le nom du fichier dans l'URL ou définissez un nom spécifique
        document.body.appendChild(a)
        a.click()

        // Nettoyage: retire l'élément <a> et libère l'URL objet
        document.body.removeChild(a)
        window.URL.revokeObjectURL(downloadUrl)
      } catch (error) {
        console.error("Error downloading the file:", error)
      }
    },
    async openPreview(item) {
      this.selectedPreviewItem = item // Stocke l'item sélectionné
      this.previewedUrl = await this.previewFile(item.url) // Récupère l'URL du fichier à prévisualiser

      this.dialogPreview = true // Ouvre la boîte de dialogue
    },
    closePreview() {
      this.selectedPreviewItem = null // Réinitialise l'item sélectionné
    },
    isPreviewOpen(item) {
      return this.selectedPreviewItem && this.selectedPreviewItem.id === item.id
    },
  },
  async created() {
    this.getAccountFiles()
    this.getAccountPays()
  },
}
</script>

<style>
.import .v-btn--icon.v-size--default {
  height: 64px !important;
  width: 64px !important;
}

.blurred {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Effet de flou */
}
</style>
